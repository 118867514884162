export default () => {
  jQuery( document ).on( 'cf.form.submit', function (event, data ) {
        //data.$form is a jQuery object for the form that just submitted.
      
      //apply a css to form
      jQuery(
        '.page-feuilleter-mesures-data .caldera-grid, .page-feuilleter-tolerie-data .caldera-grid'
      ).css( {
        visibility: 'hidden',
        display: 'none'
      });
        
  });
}