jQuery( document ).ready(function($){
    "use strict";
    /*Error Notice Message Code*/
    setTimeout(function(){
        jQuery('.wlrp-error_message1').css({'top':'0%'});
    },1);
    
    function wlrp_error_message_hide(){
        setTimeout(function(){
            jQuery('.wlrp-error_message1').css({'top':'-100%'});
        },4000);
    }
    if(wlrp_ajax_url.ajax_data != 'yes'){
        wlrp_error_message_hide();
    }
    
    /*Ajax login and registartion*/
    function wlrp_form_submit(){
        var wlrp_form_off_login_options = {
            type: "post",
            beforeSubmit:  wlrp_showRequest_login,
            success: showResponse_login_off
        }
        
        var wlrp_form_off_reg_options = {
            type: "post",
            beforeSubmit:  wlrp_showRequest_reg,
            success: showResponse_reg_off
        }
        
        var wlrp_form_off_reset_options = {
            type: "post",
            beforeSubmit: wlrp_showRequest_reset,
            success: showResponse_reset_off
        }
        
        // if (typeof jQuery('.woocommerce-form-login').ajaxForm !== "undefined") { 
        jQuery('.woocommerce-form-login').ajaxForm(wlrp_form_off_login_options);
        jQuery('.woocommerce-form-register').ajaxForm(wlrp_form_off_reg_options);
        jQuery('.woocommerce-ResetPassword').ajaxForm(wlrp_form_off_reset_options);
        // }
    }
    
    if(wlrp_ajax_url.ajax_data == 'yes'){
        wlrp_form_submit();
    }

    function showResponse_login_off(responseText, statusText, xhr, $form){
        if(statusText == 'success'){
            var h = jQuery(responseText).find('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            console.log('Login response : ', h);
            if(typeof h == 'undefined'){
                h = jQuery(responseText).filter('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            }
            if(typeof h == 'undefined'){
                h = '';
            }
            console.log(h);
            if(h != ''){
                jQuery('.wlrp-error_message1').html(h).css({'top':'0%'});
                $('.wlrp-cover-spin').remove();
                wlrp_error_message_hide();
            }else{
                jQuery('.wlrp-error_message1').html(h).css({'top':'0%'});
                $('.wlrp-cover-spin').remove();
                wlrp_error_message_hide();
                setTimeout(function(){
                    window.location.reload();
                }, 300);
                // jQuery.ajax({
                // 	type: 'POST',
                // 	url: wlrp_ajax_url.url,
                // 	data:  'action=wlrp_get_login_redirect_url',
                // 	success: function(rr) {
                // 		rr = $.trim(rr);
                // 		if(rr != ''){
                // 			console.log('redirect');
                // 			window.location = rr;
                // 		}
                // 		if(rr == ''){
                // 			console.log('reload');
                // 			location.reload(true);
                // 		}
                // 	}
                // });	
            }
            console.log('Status', statusText);
        }
    }
    
    function wlrp_showRequest_login(formData, jqForm, options){
        $('.woocommerce-form-login').append('<div class="wlrp-cover-spin"><span></span></div>');
        return true; 
    }
    
    function showResponse_reg_off(responseText, statusText, xhr, $form){
        if(statusText == 'success'){
            var h = jQuery(responseText).find('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            console.log(h);
            if(typeof h == 'undefined'){
                h = jQuery(responseText).filter('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            }
            if(typeof h == 'undefined'){
                h = '';
            }
            console.log(h);
            if(h != ''){
                jQuery('.wlrp-error_message1').html(h).css({'top':'0%'});
                $('.wlrp-cover-spin').remove();
                wlrp_error_message_hide();
            }else{
                jQuery.ajax({
                    type: 'POST',
                    url: wlrp_ajax_url.url,
                    data:  'action=wlrp_get_registration_redirect_url',
                    success: function(rr) {
                        rr = $.trim(rr);
                        if(rr != ''){
                            console.log('redirect');
                            window.location = rr;
                        }
                        if(rr == ''){
                            console.log('reload');
                            location.reload(true);
                        }
                    }
                });
            }
            console.log(statusText);
        }
    }
    
    function wlrp_showRequest_reg(formData, jqForm, options){
        $('.woocommerce-form-register').append('<div class="wlrp-cover-spin"><span></span></div>');
        return true; 
    }
    
    function showResponse_reset_off(responseText, statusText, xhr, $form){
        if(statusText == 'success'){
            var h = jQuery(responseText).find('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            console.log(h);
            if(typeof h == 'undefined'){
                h = jQuery(responseText).filter('div.wlrp-error_message,div.woocommerce-notices-wrapper').html();
            }
            if(typeof h == 'undefined'){
                h = '';
            }
            
            if(h != ''){
                jQuery('.wlrp-error_message1').html(h).css({'top':'0%'});
                $('.wlrp-cover-spin').remove();
                wlrp_error_message_hide();

                if (h.includes('avec succès')) {
                    jQuery('.woocommerce-message').append("Redirection vers la page de connexion en cours ...");
                    setTimeout(function () { window.location.href = vars.baseUrl + '/mon-compte'; }, 1500);   
                }
            }else{
                window.location.href = wlrp_ajax_url.reset_url+'?reset-link-sent=true';	
            }
        }
    }
    
    function wlrp_showRequest_reset(formData, jqForm, options){
        $('.woocommerce-ResetPassword').append('<div class="wlrp-cover-spin"><span></span></div>');
        return true; 
    }
    
    
    
    /*Click text field Focus*/
    $( ".wlrp-field-skin-i5 .woocommerce-Input,.wlrp-field-skin-i6 .woocommerce-Input" ).each(function(){
        if($(this).val() != ''){
            $(this).parent('.woocommerce-FormRow').addClass('wlrp-field-active');  
        }
        
    });
    
    $( ".wlrp-field-skin-i5 .woocommerce-Input,.wlrp-field-skin-i6 .woocommerce-Input" ).focusin(function() {
        $(this).parent('.woocommerce-FormRow').addClass('wlrp-field-active');
    }).focusout(function(){
        if($(this).val() == ''){
            $(this).parent('.woocommerce-FormRow').removeClass('wlrp-field-active');  
        }
    });

    /*click functionality*/
    $( document ).on( 'click', '.wlrp-popup-open', function( e ){
        var hrf = $(this).attr('href');
        var arr = [ '#wlrp-popup-login' ,'#wlrp-popup-register', '#wlrp-popup-password' ];
        var current_tab = '';

        if(jQuery('body').hasClass('logged-in')) {
            return false;
        }

        if( typeof hrf == 'undefined' ){
            hrf = $(this).find( 'a' ).attr('href');
        }

        if( typeof hrf != 'undefined' && jQuery.inArray( hrf, arr ) >  -1 ){
            $( '.wlrp-popup' ).removeClass('wlrp-popup-show');
            $( hrf ).addClass('wlrp-popup-show');
            if(hrf == '#wlrp-popup-login'){
                current_tab = 'login';	
            }
            if(hrf == '#wlrp-popup-register'){
                current_tab = 'register';	
            }
            if(hrf == '#wlrp-popup-password'){
                current_tab = 'password';	
            }
            $('.wlrp-popup-modal-inner').removeClass('wlrp-popup-active-login wlrp-popup-active-register wlrp-popup-active-password').addClass('wlrp-popup-active-'+current_tab);
        }else{
            $( '.wlrp-popup' ).removeClass('wlrp-popup-show');
            $( '#wlrp-popup-login' ).addClass('wlrp-popup-show');
            $('.wlrp-popup-modal-inner').removeClass('wlrp-popup-active-login wlrp-popup-active-register wlrp-popup-active-password').addClass('wlrp-popup-active-login');
        }
        
        if( $( '.wlrp-popup-modal-off' ).length > 0 ){
            $('html, body').animate({
                scrollTop: $( '.wlrp-popup-modal-off' ).offset().top - 30
            }, 400);
            $( '.wlrp-popup-modal-overlay' ).show();
        }else{
            var ftop = '0%';
            if ( $(window).width() < 700 ){
                ftop = '0%';
            }
            $( '.wlrp-popup-modal-overlay, .wlrp-popup-modal-on' ).show();
            $( '.wlrp-popup-modal-on' ).addClass('wlrp-opend').css('visibility','visible').animate({ top : ftop }, 400 );
        }

        e.preventDefault();
        e.stopPropagation();
    });

    // login/registrastion/forgot password inner click
    $( document ).on( 'click', '.wlrp-popup-toggle', function( e ){
        var href = $(this).attr('href');
        var curr_tab = '';
        $('.woocommerce-error').remove();
            

        if (typeof href  !== "undefined"){
            $( '.wlrp-popup' ).removeClass('wlrp-popup-show').promise().done(function(){
                 $(href).addClass('wlrp-popup-show');
                 if(href == '#wlrp-popup-login'){
                    curr_tab = 'login';	
                }
                if(href == '#wlrp-popup-register'){
                    curr_tab = 'register';	
                }
                if(href == '#wlrp-popup-password'){
                    curr_tab = 'password';	
                }
                $('.wlrp-popup-modal-inner').removeClass('wlrp-popup-active-login wlrp-popup-active-register wlrp-popup-active-password').addClass('wlrp-popup-active-'+curr_tab);
            });
        }

        e.preventDefault();
        e.stopPropagation();
    } );

    //close code
    $( document ).on( 'click', '.wlrp-popup-modal-overlay, .wlrp-popup-close a', function( e ){
        woo_login_styler_esc();
        e.preventDefault();
        e.stopPropagation();
    });

    $(document).keyup(function(e) {
         if (e.keyCode == 27) { // escape key maps to keycode `27`
            woo_login_styler_esc();
        }
    });
});
function woo_login_styler_esc(){
    jQuery( '.wlrp-popup-modal-overlay' ).fadeOut(100);
    jQuery( '.wlrp-popup-modal-on' ).removeClass('wlrp-opend').animate({ top :'-200%', }, 250, function(){
        jQuery( '.wlrp-popup-modal-on' ).css({ 'display' : 'none'})
    } );
}