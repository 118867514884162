module.exports.serializeObject = (form) => {
    var o = {};
    var a = jQuery(form).serializeArray();
    jQuery.each(a, function() {
            if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
}