// Import libraries
import { serializeObject } from './libs/serializeObject';
import { default as freeVueInit } from './forms/free-vue';
// import { default as analytics, handleFrameEvents } from './libs/analytics';
require('./main.scss');
require('./plugins/login/loginHandler');

const dropdownSelector = '#wd-dropdown-account-btn';

// wd-scripts/assets/js/scripts-main.js
//import('../../wd-faq/assets/js/wd-faq.js');

// Init function on document ready
jQuery(document).on('ready', function ($) {
  initThemeFunctions();
});

function initThemeFunctions() {
  eventFormHandler();
  eventCardClicks();
  annonceFormHandler();
  newslettersFormHandler();
  handleModalOpening();
  addressFormHandler();
  setTimeout(() => handleDropdownMenu(), 0);
  freeVueInit();
  countInsAds();
  // analytics();
  //mauticSubscriptionConfirmation()
}

///////////////////////////////////////////// Functions ////////////////////////////////////////////////////


function countInsAds(){
  var data = {
      'action': 'update_meta',
      'name': 'Digikey-banner'
  };
  // since 2.8 ajaxurl is always defined in the admin header and points to admin-ajax.php
  jQuery('body').on('click', 'a', function(){
    console.log('clicked link', jQuery(this));
  })

  jQuery('body').on('click', 'ins', function(){
    console.log('clicked ad');
    jQuery.post(themeVars.ajaxurl, data, function(response) {
        console.log('Got this from the server: ', response);
    });
  })
}

/**
 * Pop up a subscription confirmation on submit (sidebar newsletters form)
 */
function mauticSubscriptionConfirmation() {
  // To do (see network/themes/network-front-v2/resources/assets/scripts/routes/common.js fonction gestionFormulaireNewsletter())
}

/**
 * Handle dropdown header submenu
 */
function handleDropdownMenu() {
  jQuery(document.body).on('wc_fragments_refreshed', function () {
    console.log('Fragment refreshed now');
  })
  jQuery("body").on("mouseover", dropdownSelector, toggleShow);
  jQuery("body").on("mouseout", dropdownSelector, toggleHide);
}

function toggleShow() {
  let submenu = document.querySelector('.wd-account-submenu');
  submenu.classList.remove('hide-account-submenu');
}

function toggleHide() {
  let submenu = document.querySelector('.wd-account-submenu');
  submenu.classList.add('hide-account-submenu');
}

/**
 * Handle modal opening and closing
 * 
 */
function handleModalOpening() {

  jQuery(".wd-close-modal").click(function (e) {
    var sourceClose = jQuery(this).attr("id");

    switch (sourceClose) {
      case 'wd-close-event':
        jQuery('#wd-event-modal-backdrop, .wd-event-modal-wrapper').toggleClass('wd-modal-show');
        break;
      case 'wd-close-address':
        jQuery('#wd-address-modal-backdrop, .wd-address-modal-wrapper').toggleClass('wd-modal-show');
        break;
      case 'wd-close-annonces':
        jQuery('#wd-annonces-modal-backdrop, .wd-annonces-modal-wrapper').toggleClass('wd-modal-show');
        break;
      default:
        console.log(`Error: can't find ${sourceClose}.`);
    }
  });

  // close modal by clicking on the backdrop
  jQuery(".wd-backdrop").click(function (e) {
    var sourceBackdrop = jQuery(this).attr("id");

    switch (sourceBackdrop) {
      case 'wd-event-modal-backdrop':
        jQuery('#wd-event-modal-backdrop, .wd-event-modal-wrapper').toggleClass('wd-modal-show');
        break;
      case 'wd-address-modal-backdrop':
        jQuery('#wd-address-modal-backdrop, .wd-address-modal-wrapper').toggleClass('wd-modal-show');
        break;
      case 'wd-annonces-modal-backdrop':
        jQuery('#wd-annonces-modal-backdrop, .wd-annonces-modal-wrapper').toggleClass('wd-modal-show');
        break;
      default:
        console.log(`Error: can't find ${sourceBackdrop}.`);
    };
  });

  // Handle click on modal btn
  jQuery(".wd-modal-handler").click(function (e) {
    var sourceBtn = jQuery(this).attr("id");

    switch (sourceBtn) {
      case 'wd-event-btn':
        jQuery('#wd-event-modal-backdrop, .wd-event-modal-wrapper').addClass('wd-modal-show', 1000, "easeOutSine");
        break;
      case 'wd-address-btn':
        jQuery('#wd-address-modal-backdrop, .wd-address-modal-wrapper').addClass('wd-modal-show', 1000, "easeOutSine");
        break;
      case 'wd-annonces-btn':
        jQuery('#wd-annonces-modal-backdrop, .wd-annonces-modal-wrapper').addClass('wd-modal-show', 1000, "easeOutSine");
        break;
      default:
        console.log(`Error: can't find ${sourceBtn}.`);
    }
    e.preventDefault();
    return;
  });
}


/**
 * Add permalink on event cards click
 * @return boolean
 * 
 */
function eventCardClicks() {
  jQuery('body').on('click', '.action-link', function (e) {
    window.location.href = jQuery(this).attr('data-link');
    return false;
  });
}

/**
 * Handle submit on new event form :
 *  - Disable wpem preview and confirm form
 *  - Replace it with a message in the form modal
 *  - Manually post a new event
 */
function eventFormHandler() {
  console.log('Loading form eventFormHandler');
  jQuery('body').on("submit", '#submit-event-form', function (e) {
    var self = this;
    jQuery(self).find('.wpem-theme-button').attr('disabled', 'disabled');

    var formulaire = document.querySelector('#submit-event-form');
    var data = serializeObject(formulaire);

    //AJAX
    jQuery.ajax({
      type: 'POST',
      url: ajaxurl,
      data: {
        action: 'new_event',
        data: data
      },
      success: function (resp) {
        console.log(resp);
        if (resp.status === 'success') {
          let content = jQuery('.wd-event-modal-wrapper');
          content.find('h1').html('Merci !');
          content.find('#wd-event-form-wrapper').hide();
          content.find('#wd-event-confirm').html('Votre évènement a été pris en compte et sera publié après validation par l\'équipe de rédaction.');

          // auto close and reset the modal
          let modal = $('.wd-event-modal-wrapper');
          let backdrop = $('#wd-event-modal-backdrop');
          setTimeout(function() {
            modal.removeClass('wd-modal-show');
            backdrop.removeClass('wd-modal-show');
            $("form").trigger("reset");
            content.find('h1').html('Ajouter un nouvel évènement');
            content.find('#wd-event-form-wrapper').show();
            content.find('#wd-event-confirm').html('');
            jQuery(self).find('.wpem-theme-button').prop("disabled", false);
          }, 3000);          
          // window.location.href = '/?post_type=event_listing&p=' + resp.id;
        } else {
          jQuery(self).find('.wpem-theme-button').prop("disabled", false);
        }
      }
    });

    return false;

  });
}

// function uploadFile(file) {
//   console.log('Uploading file', file);

//   var fd = new FormData();
//   fd.append("file", file[0]);
//   fd.append('action', 'upload_file_annonce');

//   jQuery.ajax({
//     type: 'POST',
//     url: vars.ajaxurl,
//     contentType: false,
//     processData: false,
//     data: fd,
//     error: function() {
//       alert('An error occurred...');
//     },
//     success: function(resp) {
//       console.log('voici la réponse :', { resp });
//       if (resp.status === 'success') {
//         return true;
//       } else {
//         return false;
//       }
//     }
//   });
//   return false;
// }

/**
 * Handle submit on new annonce form
 */
function annonceFormHandler() {
  console.log('Loading form annonceFormHandler');
  jQuery('body').on("submit", '#wd-submit-annonce-form', function (e) {
    var self = this;
    jQuery(self).find('.wd-modal-annonce-submit-btn').attr('disabled', 'disabled');
    //console.log('Submit !');

    var annonceForm = document.querySelector('#wd-submit-annonce-form');
    var data = serializeObject(annonceForm);
    //Debug 
    console.log(data);

    const fileToUpload = jQuery('#wd-annonce-image')[0].files;

    var fd = new FormData();
    fd.append("wd-annonce-image", fileToUpload[0]);
    fd.append('action', 'new_annonce');
    fd.append('data', JSON.stringify(data));

    //AJAX
    jQuery.ajax({
      type: 'POST',
      url: vars.ajaxurl,
      contentType: false,
      processData: false,
      data: fd,
      error: function () {
        alert('toutes nos excuses, il y a eu une erreur...');
      },
      success: function (resp) {
        console.log('voici la réponse : ' + resp.status);
        if (resp.status === 'success') {
          // if(!fileToUpload || (fileToUpload && uploadFile(fileToUpload))) {
          var formWrapper = jQuery('.wd-annonces-form-wrapper');
          formWrapper.find('#wd-annonce-confirm').html('Votre annonce a été prise en compte');
          formWrapper.find('#wd-submit-annonce-form').hide();
          formWrapper.find('.wd-info-required').hide();
          formWrapper.find('#wd-thanks-title').html('Merci !');
          //console.log("Hooray, it worked!");
          // }
          // console.log("File didn't upload");
        } else {
          jQuery(self).find('.wd-modal-annonce-submit-btn').prop("disabled", false);
          //console.log("Try again");
        }
      }
    });

    return false;

  });
}

/**
 * Handle submit on newsletters preferences form
 */
function newslettersFormHandler() {
  console.log('Loading form newslettersFormHandler');
  jQuery('body').on("submit", '#wd-newsletters-preferences-form', function (e) {
    var self = this;
    jQuery(self).find('.wd-nl-form-submit-btn').attr('disabled', 'disabled');
    // Debug //
    e.preventDefault();
    console.log('Submit !');

    var formulaire = document.querySelector('#wd-newsletters-preferences-form');
    var data = serializeObject(formulaire);

    //AJAX
    jQuery.ajax({
      type: 'POST',
      url: vars.ajaxurl,
      data: {
        action: 'update_user_preferences',
        data: data
      },
      error: function () {
        alert('An error occurred...');
      },
      success: function (resp) {
        console.log(resp);
        if (resp.status === 'success') {
          var wrapper = jQuery('.wd-nl-tables-wrapper');
          wrapper.find('#wd-nl-success').html('Vos préférences ont été prises en compte').show();
          jQuery(self).find('.wd-nl-form-submit-btn').attr("disabled", null);
          setTimeout(function(){
            $('#wd-nl-success').fadeOut();
          }, 2000);
          //console.log("Working great !");
        } else {
          jQuery(self).find('.wd-nl-form-submit-btn').prop("disabled", false);
          //console.log("Try again");
        }
      }
    });

    return false;

  });
}

/**
* Handle submit on new address form
*/
function addressFormHandler() {

  console.log('Loading form addressFormHandler');
  jQuery('body').on("submit", '#wd-submit-address-form', function (e) {
    var self = this;
    jQuery(self).find('.wd-modal-address-submit-btn').attr('disabled', 'disabled');

    var addressForm = document.querySelector('#wd-submit-address-form');
    var data = serializeObject(addressForm);

    const fileToUpload = jQuery('#wd-address-image')[0].files;

    var formdata = new FormData();
    formdata.append("wd-address-image", fileToUpload[0]);
    formdata.append('action', 'new_address');
    formdata.append('data', JSON.stringify(data));

    //AJAX
    jQuery.ajax({
      type: 'POST',
      url: vars.ajaxurl,
      contentType: false,
      processData: false,
      data: formdata,
      error: function (resp) {
        alert('toutes nos excuses, il y a eu une erreur...');
        console.log('voici la réponse : ' + resp.status);
      },
      success: function (resp) {
        //console.log('voici la réponse : ' + resp.status);
        if (resp.status === 'success') {
          // if(!fileToUpload || (fileToUpload && uploadFile(fileToUpload))) {
          var formWrapper = jQuery('.wd-address-form-wrapper');
          formWrapper.find('#wd-address-confirm').html('Votre adresse a bien été prise en compte. <br>Elle apparaîtra en ligne après validation par les administrateurs.');
          formWrapper.find('#wd-submit-address-form').hide();
          formWrapper.find('.wd-info-required').hide();
          formWrapper.find('#wd-thanks-title').html('Merci !');
          console.log("Hooray, it worked!");
          // }
          // console.log("File didn't upload");
        } else {
          jQuery(self).find('.wd-modal-address-submit-btn').prop("disabled", false);
          console.log("Try again");
        }
      }
    });

    return false;

  });
}




